<template>
  <div v-if="page && idleScreen == false" class="row" id="render">
    <!-- content -->
    <img src="https://www.theshadingcompany.ae/wp-content/uploads/2019/11/The-Shading-Company-Logo.png" alt="shading company logo" id="logo" />
    <div class="col-12 my-5">
      <h1>{{ page.title.rendered }}</h1>
    </div>
    <div class="col-9">
      <p class="description" v-html="page.product_description" />
    </div>
    <div class="col-3 specifications">
      <h2>Specifications</h2>
      <ul>
        <li v-for="bullet in bullets" v-bind:key="bullet">{{ bullet }}</li>
      </ul>
    </div>
    <!-- images -->
    <div class="col-6 imageContainer" v-if="maxImages > 0">
      <div
        v-for="(im, index) in page.product_image"
        v-bind:key="im"
        :style="{ backgroundImage: `url(${im.guid})`, backgroundSize: 'cover' }"
        :class="[`image-${index}`]"
      ></div>
    </div>
    <!-- video -->
    <div class="col-6" id="videoContainer" v-if="page.video">
      <video autoplay muted loop id="videoClip">
        <source :src="page.video.guid" type="video/mp4" id="videoSource" />
      </video>
    </div>
  </div>
  <div v-else>
    <video autoplay muted loop id="videoClipIdle" v-if="idleVideoURL">
      <source :src="idleVideoURL" type="video/mp4" id="videoSourceIdle" />
    </video>
  </div>
</template>

<script>
import { db } from "@/firebase";
import "jquery/dist/jquery.min.js";
import $ from "jquery";
document.title = "Showroom Render";

export default {
  data() {
    return {
      page: null,
      maxImages: 0,
      currentImage: 0,
      bullets: [],
      timer: null,
      idleTimer: null,
      idleScreen: false,
      idleVideoURL: "https://www.theshadingcompany.ae/wp-content/uploads/2021/10/The-Shading-Company-Promo-Video.mp4",
    };
  },
  created() {
    db.ref("SlideShow/currentPage").on("value", (snapshot) => {
      if (!snapshot) return;
      var pageID = snapshot.val().pageID;

      console.log("clear timer");

      this.idleScreen = false;
      clearInterval(this.idleTimer);
      this.idleTimer = setInterval(this.idleDisplay, 3 * 60000);

      if (!pageID) return;
      pageID--;

      if (!this.$root.data[pageID]) return;
      this.page = this.$root.data[pageID];

      if (this.page.product_image && this.page.product_image.length) this.maxImages = this.page.product_image.length;
      else this.maxImages = 0;

      this.bullets = [];
      this.bullets.push(this.page.bullet_point_1);
      this.bullets.push(this.page.bullet_point_2);
      this.bullets.push(this.page.bullet_point_3);
      this.bullets.push(this.page.bullet_point_4);

      var vid = document.getElementById("videoClip");
      if (vid) vid.load();
      clearTimeout(this.timer);
      this.slideshow();
    });
  },
  methods: {
    slideshow() {
      this.timer = setTimeout(this.slideshow, 5000);

      // console.log(this.currentImage);
      if (this.maxImages == 1) {
        clearTimeout(this.timer);
        return;
      }
      for (let i = 0; i < this.maxImages; i++) {
        $(`.image-${i}`).fadeOut(500);
      }
      if (this.currentImage < this.maxImages - 1) this.currentImage += 1;
      else {
        this.currentImage = 0;
      }
      $(`.image-${this.currentImage}`)
        .delay(500)
        .fadeIn(1000);
    },
    idleDisplay() {
      console.log("set timer");
      clearInterval(this.idleTimer);
      this.idleScreen = true;
      console.log(this.idleVideoURL);
      var vid = document.getElementById("videoClipIdle");
      if (vid) {
        vid.load();
        vid.play();
      }
    },
  },
};
</script>
<style>
body {
  overflow: hidden;
}
</style>
<style scoped>
#render {
  padding: 0 50px;
}
#logo {
  position: absolute;
  width: 150px;
  top: 30px;
  right: 20px;
}
.description {
  font-size: 38px;
  font-weight: 300;
  color: #666;
  line-height: 1.3em;
  padding-right: 1em;
  border-right: 1px solid #0068a1;
}
.imageContainer {
  height: 499px;
  overflow: hidden;
}
.imageContainer div {
  width: 100%;
  height: 100%;
}
video {
  width: 100%;
}
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-active {
  transition: opacity 0.5s ease;
  transition-delay: 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
h1 {
  color: #0068a1;
}
h2 {
  font-size: 28px;
  padding-top: 10px;
  color: #666;
}
.specifications {
  padding-left: 30px;
}
ul li {
  list-style-type: circle;
  font-size: 18px;
  color: #666;
}
</style>
